import Vue from 'vue';
import axios from 'axios';
import router from '../router/index'
import appStore from '@/store'
import { showNotify } from 'vant';

//let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
let base = process.env.NODE_ENV == "production" ? process.env.VUE_APP_BASE_API:''; 
export const BaseApiUrl = base;
export const AppId = process.env.VUE_APP_BASE_APPID;
export const PayAppId = "1";
// 请求延时Pay
axios.defaults.timeout = 60000
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = appStore.userStore.tokenExpire
        if (appStore.userStore.token && (curTime < expiretime && appStore.userStore.tokenExpire)) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            if (config && config.headers) { 
                config.headers.Authorization = "Bearer " + appStore.userStore.token;
            }    
        }

        saveRefreshtime();

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => { 
        return response; 
    },
    error => {
        // 超时请求处理
        error.response.data.success = false;
        var originalRequest = error.config;
        if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 && !originalRequest._retry){
            error.response.data.msg = "请求超时！"; 
            originalRequest._retry = true 
        }
        else if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return  refreshToken({token: window.localStorage.Token}).then((res) => {
                        if (res.success) {
                            showNotify({ type: 'danger', message: 'refreshToken success! loading data...' });
                            appStore.userStore.updateToken(res.response.token);

                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expires_in));
                            appStore.userStore.updateTokenExpire(expiredate);

                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                }

            }
            // 403 无权限
            else if (error.response.status == 403) {
                error.response.data.msg = "失败！该操作无权限"; 
            }
            // 429 ip限流
            else if (error.response.status == 429) {
                error.response.data.msg =  "刷新次数过多，请稍事休息重试！";
            }else if (error.response.status == 404) {
                // 404 不存在
                error.response.data.msg = "失败！访问接口不存在";
           }else if (error.response.status == 500) {
               // 500 服务器异常
               error.response.data.msg = "失败！服务器异常"; 
           }else if (error.response.status == 405) {
               // 405 请求http方法错误
               error.response.data.msg = "失败！请求http方法错误";  
           }else if (error.response.status == 415) {
               // 415 参数没有指定Body还是Query
               error.response.data.msg= "失败！参数没有指定Body还是Query";   
           }else {
               //其他错误参数
               error.response.data.msg = '失败！请求错误' + error.response.status;    
           }
        }
        else{
            error.response.data.msg =  "失败！服务器断开";  
        }
        return error.response; // 返回接口返回的错误信息
    }
);


// 登录
export const requestLogin =  ( params : any) => {
    return axios.get(`${base}/api/login/jwttoken3.0`, {params: params}).then(res => res.data);
};

export const refreshToken =  ( params : any) => {
    return axios.get(`${base}/api/login/RefreshToken`, {params: params}).then(res => res.data);
};

export const saveRefreshtime = () => {
    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))
    let refreshCount=1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime=nowtime>expiretime ? nowtime:expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    }else {
        window.localStorage.refreshtime = new Date(-1);
    }
};

const ToLogin = () => {
    appStore.userStore.clearToken()
     router.push({name:'login'})
};

export const docLoginApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/login`, {params: params});
};

export const getMember = (params: any) => {
    return axios.get(`${base}/api/Member/QueryMember`, {params: params});
};

export const getOpenIdByCode = (params: any) => {
    return axios.get(`${base}/api/WeChat/GetOpenIdByCode`, params);
};

export const getPhoneByPasswort = (params: any) => {
    return axios.get(`${base}/api/WeChat/GetOpenIdByCode`, params);
};

//home菜单
export const getMenu = (params: any) => {
    return axios.get(`${base}/api/IHMenu/client-get`, {params: params});
};

//院内信息
export const getHospitalInfo = (params: any) => {
    return axios.get(`${base}/api/Hospital/client-get`, {params: params});
};


//card管理
export const getBindDefPat = (params: any) => {
    return axios.post(`${base}/api/Member/QueryBindPatient`, params);
};
export const getBindPatients = (params: any) => {
    return axios.post(`${base}/api/Member/QueryBindPatients`, params);
};
export const editDefPat = (params: any) => {
    return axios.post(`${base}/api/Member/UpdateBingDefaultPatient`, params);
};
export const getPatients = (params: any) => {
    return axios.get(`${base}/api/outpatient/getPatients`, {params: params});
};
export const getPatientByCardNo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getPatientByCardNo`, {params: params});
};
export const bindPatient = (params: any) => {
    return axios.post(`${base}/api/Member/BindPatient`, params);
};
export const unbindPatient = (params: any) => {
    return axios.post(`${base}/api/Member/DeleteBingPatient`, params);
};
export const createCard = (params: any) => {
    return axios.post(`${base}/api/Member/CreatePatient`, params);
};
export const bindInPatient = (params: any) => {
    return axios.post(`${base}/api/Member/BindInPatient`, params);
};

//配送地址
export const getShippingAddrbyCardNO = (params: any) => {
    return axios.get(`${base}/api/IHShippingAddr/GetAll`, {params: params});
};
export const getShippingAddrbyId = (params: any) => {
    return axios.get(`${base}/api/IHShippingAddr/Get`, {params: params});
};
export const removeShippingAddr = (params: any)  => {
    return axios.delete(`${base}/api/IHShippingAddr/delete`, {params: params});
};
export const editShippingAddr = (params: any)  => {
    return axios.put(`${base}/api/IHShippingAddr/put`, params);
};
export const addShippingAddr = (params: any)  => {
    return axios.post(`${base}/api/IHShippingAddr/post`, params);
};
export const queryShippingAddr = (params: any) => {
    return axios.get(`${base}/api/IHShippingAddr/GetDef`, {params: params});
};





//opo挂号
export const getDept = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDept`, {params: params});
};
export const getSchemaByDate = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDocsByDate`, {params: params});
};


//报告管理
export const queryOutpLisReptMaster = (params: any) => {
    return axios.get(`${base}/api/outpatient/getOutpLisReptMaster`, {params: params});
};
export const queryOutpLisReptDetail = (params: any) => {
    return axios.get(`${base}/api/outpatient/getOutpLisReptDetail`, {params: params});
};
export const queryOutpPacsReptMaster = (params: any) => {
    return axios.get(`${base}/api/outpatient/getOutpPacsReptMaster`, {params: params});
};
export const queryOutpPacsReptMasterVideo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getOutpPacsReptMasterVideo`, {params: params});
};

export const getUserByToken = (params: any) => {
    return axios.get(`${base}/api/user/getInfoByToken`, {params: params}).then(res => res.data);
};

// 用户管理
export const getUserByCardNo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getUserByCardNo`, {params: params});
};
export const getUserListPage = (params: any) => {
    return axios.get(`${base}/api/user/get`, {params: params});
};
export const removeUser = (params: any)  => {
    return axios.delete(`${base}/api/user/delete`, {params: params});
};
export const editUser = (params: any)  => {
    return axios.put(`${base}/api/user/put`, params);
};
export const addUser = (params: any)  => {
    return axios.post(`${base}/api/user/post`, params);
};
export const batchRemoveUser = (params: any)  => {
    return axios.delete(`${base}/api/Claims/BatchDelete`, {params: params});//没做
};

//门诊挂号
export const queryDept = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDept`, {params: params});
};
export const queryDocsByDate = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDocsByDate`, {params: params});
};
export const queryDocScheduling = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDocScheduling`, {params: params});
};
export const querySchedulById = (params: any) => {
    return axios.get(`${base}/api/outpatient/getSchedulById`, {params: params});
};
export const queryOutpSchedulePartime = (params: any) => {
    return axios.get(`${base}/api/outpatient/getOutpSchedulePartime`, {params: params});
};
export const queryDocInfoByCode = (params: any) => {
    return axios.get(`${base}/api/outpatient/getDocInfoByCode`, {params: params});
};
export const queryHistoryOrder = (params: any) => {
    return axios.get(`${base}/api/order/mop_historyOrderQuery`, {params: params});
};
export const payRefund = (params: any) => {
    return axios.post(`${base}/api/WeChatPay/PayRefund`, params);
};//退号
export const orderCancel = (params: any) => {
    return axios.post(`${base}/api/Order/MOP_OrderCancel`, params);
};//取消订单
export const orderRefund = (params: any) => {
    return axios.post(`${base}/api/Order/MOP_OrderRefund`, params);
};//退号
export const queryRegHistory = (params: any) => {
    return axios.get(`${base}/api/outpatient/mop_regHistoryQuery`, {params: params});
};


//#region 在线问诊
export const getOnlineSchemaByDate = (params: any) => {
    return axios.get(`${base}/api/outpatient/online/getDocsByDate`, {params: params});
};
export const queryOnlineDocAsync = (params: any) => {
    return axios.get(`${base}/api/outpatient/queryOnlineDocAsync`, {params: params});
};
export const createOnlineHealthFromApi = (params: any) => {
    return axios.post(`${base}/api/outpatient/onlineHealthFrom/post`, params);
};
export const getOnlineHealthFromApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/onlineHealthFrom/get`, {params: params});
};
export const onlineChatSendApi = (params: any) => {
    return axios.post(`${base}/api/outpatient/online/chat/send`, params);
};
export const getChatRecordApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/online/chat/record/get`, {params: params});
}; 
export const queruOnlinePatientsApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/online/patients/get`, {params: params});
}; 
export const queruOnlinePatientsByReceiptApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/online/patients/get/receipt`, {params: params});
}; 
export const updateReceiptStateApi = (params: any) => {
    return axios.post(`${base}/api/outpatient/online/receiptState/update`, params);
}; 
//#endregion

//#region 在线开处方
export const queryDrugInfoPageApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/drugInfo/page`, {params: params});
};
export const queryDrugInfoByCodeApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/drugInfo/get`, {params: params});
};
export const queryDiagnoseInfoPageApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/diagnose/page`, {params: params});
};
export const queryFrequencyApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/frequency/get`, {params: params});
};
export const queryUsageApi = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/usage/get`, {params: params});
};
export const queryHistoryRx = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/rx/history/get`, {params: params});
};
export const queryHistoryRxDetail = (params: any) => {
    return axios.get(`${base}/api/outpatient/doc/online/rx/history/detail/get`, {params: params});
};
export const saveRx = (params: any) => {
    return axios.post(`${base}/api/outpatient/doc/online/rx/save`, params);
};

//#endregion


//门诊缴费
export const queryUnPayRecipes = (params: any) => {
    return axios.get(`${base}/api/outpatient/getRecipes`, {params: params});
};
export const queryUnPayRecipeDetails = (params: any) => {
    return axios.get(`${base}/api/outpatient/getRecipeDetails`, {params: params});
};
export const queryPayRecipes = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInvoices`, {params: params});
};
export const queryPayRecipeDetails = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInvoiceDetails`, {params: params});
};

//住院模块
export const queryInHospitalInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInHospitalInfo`, {params: params});
};
export const queryInHosFeeInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInHosFeeInfo`, {params: params});
};
export const queryInHosFeeDetail = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInHosFeeDetail`, {params: params});
};
export const queryInHospitalHistoryInfos = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInHospitalHistoryInfos`, {params: params});
};
export const getInHospitalHistoryInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getInHospitalHistoryInfo`, {params: params});
};



//体检模块
export const queryChkCombins = (params: any) => {
    return axios.get(`${base}/api/outpatient/combins`, {params: params});
};
export const queryChkCombinItems = (params: any) => {
    return axios.get(`${base}/api/outpatient/combinItems/${params}`);
};
export const queryChkPackageInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getChkPackageInfo`, {params: params});
};
export const queryChkPackageDetailInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getChkPackageDetailInfo`, {params: params});
};
export const queryChkUnPayInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getChkUnPayInfo`, {params: params});
};
export const queryChkInvoiceInfo = (params: any) => {
    return axios.get(`${base}/api/outpatient/getChkInvoiceInfo`, {params: params});
};
export const queryChkReport = (params: any) => {
    return axios.get(`${base}/api/outpatient/getChkReport`, {params: params});
};
export const chkAppointment = (params: any) => {
    return axios.post(`${base}/api/outpatient/chkAppointment`, params);
};
export const addChkOccupationMain = (params: any) => {
    return axios.post(`${base}/api/outpatient/addChkOccupationMain`, params);
};

//订单
export const unifiedOrder = (params: any) => {
    return axios.post(`${base}/api/order/MOP_UnifiedOrder`, params);
};
export const cancelOrder = (params: any) => {
    return axios.post(`${base}/api/order/MOP_OrderCancel`, params);
};
export const queryOrder = (params: any) => {
    return axios.get(`${base}/api/order/MOP_OrderQueryById`, params);
};
export const queryOrderResult = (params: any) => {
    return axios.get(`${base}/api/order/MOP_OrderResultQueryById`, {params: params});
};

//支付
export const queryPayMode = (params: any) => {
    return axios.get(`${base}/api/PayChannel/GetAll`, {params: params});
};

export const payByWechat = (params: any) => {
    return axios.post(`${base}/api/WeChatPay/JsApiPay`, params);
};
export const getTicket = (params: any) => {
    return axios.post(`${base}/api/WeChatPay/JsApiPay11`, params);
};

export const createDemoOrder = (params: any) => {
    return axios.post(`${base}/api/PayDemoOrder/create`, params);
};

export const refundDemoOrder = (params: any) => {
    return axios.post(`${base}/api/PayDemoOrder/refund`, params);
};

export const submitPayOrder = (params: any) => {
    return axios.post(`${base}/api/Pay/SubmitPayOrder`, params);
};


//测试
export const testapi = () => {
    console.log('api is ok.')
}

//查询新闻公告
export const reqQueryBlog = (params:any) =>{
    return axios.get(`${base}/api/Blog`, {params: params});
}

//查询队列信息
export const reqQueryQueueInfo = (params:any) =>{
    return axios.post(`${base}/api/queue/MOP_QueryQueueInfo`, params);
}


//上传文件
export const uploadFile = (params:any) =>{
    return axios.post(`${base}/api/file/uploadMultiple`, params);
}

export const deleteFile = (params:any) =>{
    return axios.delete(`${base}/api/file/delete`,  {params: params});
}

//提示信息模块
export const getTipsApi = (params:any) => {
    return axios.get(`${base}/api/tips/client-get`, { params: params });
};



